@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
.dashboard-content h3 {
  color: #222222;
  font-family: "DM Sans", DM Sans;
  font-weight: 500;
  font-size: 22px !important;
}
p,
span,
h4,
input,
td,
h5 {
  font-family: "DM Sans", DM Sans;
}

.margin-0 {
  margin: 0;
}

.house {
  text-align: left;
}

.bg-clr {
  background: #f6f7fa;
}
.bg-clr-white {
  background: white;
  box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.house img {
  width: 100%;
  height: auto;
  max-height: 270px !important;
}

.offer-comments-filter-container {
  flex-direction: row;
  justify-content: flex-end;
}

.offer-comments-filter {
  min-height: 32px !important;
  padding: 0 !important;
  margin: 8px;
  margin-right: 0;
  margin-left: 0;
  border-radius: 5px;
  background: transparent;
  border: 1px solid black;
  cursor: pointer;
}

.dashboard-list {
  padding: 30px 15px;
}

.offer {
  color: #999999;
  font-size: 16px;
  font-family: "Raleway", DM Sans;
  font-weight: 500;
  margin-bottom: 10px;
}

.in-review button {
  background: #ffc107;
  color: #222222;
}

.seller-review button {
  background: #fd8d14;
  color: #fff;
}

.accepted button {
  background: #28a745;
  color: #ffffff;
}

.declined button {
  background: #dc3545;
  color: #ffffff;
}

.executed button {
  background-color: #0a8c77;
  color: white;
}

.withdrawn button {
  background-color: #000;
  color: white;
}

.in-review button,
.accepted button,
.declined button,
.executed button,
.seller-review button,
.withdrawn button {
  border-radius: 4px;
  outline: 0;
  border: 0;
  font-size: 12px !important;
  font-family: "Raleway", DM Sans;
  font-weight: 700;
  height: 18px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}

.in-review button img,
.accepted button img,
.declined button img,
.executed button img,
.seller-review img,
.withdrawn button img {
  padding-right: 5px !important;
  margin-top: -3px !important;
}

.homeoffer-details {
  padding-left: 0;
}

.homeoffer-details h5 {
  color: #222222;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px !important;
  margin: 16px 0;
}

.homeoffer-details ul li img {
  margin-right: 5px;
}

.offfer-status-rendering-container {
  max-width: 87%;
  flex-direction: column !important;
}

.homeoffer-details ul li p {
  font-family: "DM Sans";
  font-weight: 500;
  color: #999999;
  font-size: 16px !important;
  display: inline-block;
}

.homeoffer-details ul li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.homeoffer-details ul li p span {
  font-family: "Raleway", DM Sans;
  font-weight: 500;
  color: #222222;
  font-size: 16px !important;
}

.underline {
  text-decoration: underline;
}

.black-cursor-pointer {
  color: #222222 !important;
  cursor: pointer;
  margin-right: 0 !important;
}

.homeoffer {
  background-color: #e9ecef;
  padding: 30px;
  padding-bottom: 16px;
}

.homeoffer p {
  color: #222222;
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 5px;
}

.is-viewed {
  font-size: 13px;
  margin-left: 5px;
}

.black-price-tag {
  color: #222222 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.red-price-tag {
  color: #dc3545 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.back-btn {
  padding: 0 30px;
  background-color: white;
  border: 1px solid #cccccc;
  font-size: 18px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 8px;
}

.backArrow {
  margin-right: 7px;
}

.green-btn.no-margin {
  margin-right: 0px;
}

.green-btn {
  padding: 0 30px;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 8px;
}

.green-btn:disabled {
  opacity: 0.65;
}

.btn-review {
  padding: 0 30px;
  background-color: white;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: #28a745;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.red-btn {
  padding: 0 30px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}
.other-agent-action-btn {
  padding: 0 15px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  font-size: 12px !important;
  color: white;
  border-radius: 5px;
  height: 32px;
  cursor: pointer;
}

.otheragent-header-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.red-btn:disabled {
  opacity: 0.65;
}

.mt-95 {
  margin-top: 95px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(233, 236, 239, 0.5);
}

.table-striped tbody tr:nth-of-type(odd) td {
  background-color: rgba(233, 236, 239, 0.5);
  padding: 6px 10px !important;
}
.table-striped tbody tr:nth-of-type(even) td {
  padding: 6px 10px !important;
}

.table td,
.table th {
  border: 0;
}

.bold-td {
  font-size: 16px !important;
  font-weight: bold;
  color: #222222;
  padding-left: 30px !important;
  height: 60px;
}

.normal-td {
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  height: 60px;
}

.table td,
.table th {
  /* padding: 0 30px;
  height: 60px;
  vertical-align: middle;
  width: 55%; */
  height: 60px;
  vertical-align: middle;
}

.mt-40 {
  margin-top: 40px;
}

.mb-13 {
  margin-bottom: 13px;
}

.offer-package {
  margin-top: 20px;
}

.offersMian {
  font-weight: bold;
  font-size: 22px !important;
  color: #222222;
  font-family: "Raleway", DM Sans;
}

.uploadBtn {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  padding: 0 30px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 23px;
  height: 44px;
}

.history-of-changes {
  font-weight: bold;
  font-size: 22px;
  color: #222222;
  font-family: "Raleway", DM Sans;
  margin-bottom: 30px;
  margin-top: 21px;
}

.history-changes {
  background: white;
  padding: 8px 30px;
  border-left: 3px solid #b0b0b0;
}

.history-changes p {
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  margin: 12px 0;
}

.review p {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}

.review-text {
  font-family: "Raleway", DM Sans;
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  margin-bottom: 40px;
}

.m-57 {
  margin-top: 57px;
  margin-bottom: 57px;
}

.summaries {
  font-weight: bold;
  font-size: 22px;
  color: #222222;
  font-family: "Raleway", DM Sans;
  padding-top: 40px;
}

.accordian {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordian-opened {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
}

.accordian-opened p {
  margin: 30px 0;
  font-size: 16px !important;
  color: #222222;
  font-weight: bold;
}

.accordian p {
  margin: 30px 0;
  font-size: 16px !important;
  color: #222222;
  font-weight: bold;
}

.accordian-p {
  font-size: 16px !important;
  color: #222222;
  font-weight: 500;
  padding: 8px;
}

.p-lr-30 {
  padding: 0 30px;
}

.card {
  border: 0;
}

.card-body {
  padding: 0;
}

.mb-457 {
  margin-bottom: 457px;
}
.space-between {
  justify-content: space-between;
}
.row {
  display: flex;
  flex-direction: row;
}

.m-lr-0 {
  margin: 0;
}

.dashboard-content {
  padding-bottom: 10px;
}

.terms-and-conditions-heading {
  font-weight: 900;
  color: #000;
}

.terms-and-conditions ul {
  list-style: auto;
  margin-left: 15px;
}

.terms-and-conditions ul li {
  margin-bottom: 8px;
}

.offer-detail-actions {
  padding: 8px 15px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 18px !important;
  color: black;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.fit-content {
  width: fit-content;
}

.flexEnd {
  align-items: flex-end;
}

.btn-list-margin {
  margin: 2px 0;
}

.padd-horizontal-15 {
  padding: 0 15px;
}

.action-btns {
  position: absolute;
  right: 15px;
  background: white;
  z-index: 10;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  padding: 15px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.agent-brokerage {
  font-size: 20px !important;
  font-weight: 600;
  color: #000000;
}

.mt-10 {
  margin-top: 10px !important;
}

.modal-body label {
  margin: 20px 0;
}

.accept-comment {
  width: 100% !important;
  /* margin: 20px 0px !important; */
  /* margin-top: 20px !important; */
}

.accept-comment-container {
  margin: 20px 0px !important;
}

.code-container {
  position: relative;
}

.embedable-code-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.all-users-heading {
  margin-bottom: 0;
}

.invite-user-heading {
  text-align: center;
  font-weight: 700;
  font-size: 22px !important;
  color: black;
}

.users-table-data {
  vertical-align: middle;
  font-size: 14px !important;
  margin: 0;
  font-weight: initial;
}

.row-spaceBtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.invite-user-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 20px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.align-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-profile-btn {
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
}

.offer-preview-container {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 30px;
}

.offer-preview-bg {
  background-color: #ffffff !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.verify-offer-btn {
  padding: 8px 20px;
  background-color: #ff214f;
  border: 1px solid #ff214f;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-left: 8px;
}

.display-flex {
  display: flex;
}

.name-change-input{
  width: 350px !important;
}

.doc-timestamp {
  font-size: 11px !important;
  margin: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mb2 {
  margin-bottom: 2px;
}

.video-icon {
  width: 18px;
  height: 12px;
}

.counter-offer-table-text-green {
  font-size: 12px !important;
  color: green !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.counter-offer-table-text-red {
  font-size: 12px !important;
  color: red !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.text-red {
  color: red !important;
}

.mob-only-show-offer-trends-btn {
  display: none;
}

.archive-img {
  width: 17px;
}

.offer-details-ai-score-card-container {
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  color: white;
  box-shadow: 0 0 0 3px rgba(164, 69, 255, 1);
  border: 3px solid transparent;
  font-family: "DM Sans", DM Sans;
  background-clip: padding-box;
  border-radius: 56px;
  font-size: 16px;
  padding: 0 4px;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 55px;
  height: 55px;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 31px;
  top: 13px;
}

.termination-commnet-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
  color: #999999;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.termination-comment {
  color: #999999;
  background: #f4f4f4;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
}

.row-flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.metric-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.metric-card p {
  font-size: 14px;
  margin-bottom: 0;
}

.metric-value {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}

.metric-card h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.amend-offers-toogle {
  justify-content: center !important;
  margin-bottom: 16px;
}

.amend-offers-toogle label {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.amend-offers-toogle input[type="checkbox"] {
  display: none;
} 

.agent-info-progress {
  height: 18px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 4px;
  position: relative;
}

.agent-progress-bar {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
}

.agent-info-custom-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 13px;
  color: #6c757d;
} 

.amend-notifications-toogle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amend-notifications-toogle label {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.amend-terms-modal-table {
  margin-bottom: 0;
}

.net-sheet {
  width: 13px;
}

.seller-net-footer {
  border-top: 0;
}

.seller-net-sheet-form-container {
  background: #f6f7f9;
  border: 1px solid #d4d7e0;
  border-radius: 8px;
  padding: 24px;
  margin: 50px 0 20px;
}

.seller-net-sheet-form-container label {
  margin: 0 0 4px 0;
  font-size: 16px;
}

.seller-net-sheet-form-container input {
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.seller-net-sheet-form-container button {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
  background-color: #ff214f;
  border: 0;
  font-weight: bold;
  font-size: 16px;
}

.copy-to-clipboard-btn {
  border-radius: 4px;
  height: 40px;
  width: 170px;
  margin-bottom: 0;
  background-color: #ff214f;
  border: 0;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}

.copy-to-clipboard-btn:hover {
  background-color: #ff214f;
}

.seller-net-doughnut-container {
  width: 320px; /* Ensures a perfect square container */
  height: 320px;
  margin: 0 auto;
  position: relative;
}

.seller-net-doughnut-container canvas {
  width: 320px !important;
  height: 320px !important;
  display: block;
}

.seller-net-doughnut-container .chart-center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.seller-net-doughnut-container .chart-center-text strong {
  font-size: 24px;
}

.seller-net-doughnut-container .chart-center-text p {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.net-sheet-summary {
  margin-top: 20px;
  width: 100%;
}

.net-sheet-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 16px;
}

.net-sheet-label {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.net-sheet-value {
  font-weight: bold;
  font-family: "DM Sans", DM Sans !important;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.net-sheet-total {
  font-size: 18px;
  font-weight: bold;
}

.net-sheet-result-container {
  border: 1px solid #d4d7e0;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  max-height: 1275px;
  overflow-y: scroll;
}

.net-sheet-result-container::-webkit-scrollbar {
  width: 4px; /* Reduced thickness of the scrollbar */
  border-radius: 16px;
}

/* Scrollbar track */
.net-sheet-result-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

/* Scrollbar thumb */
.net-sheet-result-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

/* Scrollbar thumb on hover */
.net-sheet-result-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.tab-button-group {
  display: flex;
  justify-content: center;
}

.tab-button {
  background: none;
  border: none;
  margin-bottom: 0;
  color: grey;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 12px 16px;
  transition: color 0.3s ease;
}

.tab-button.active {
  color: #dc3545;
  font-weight: bold;
  margin-bottom: 0;
  border-bottom: 2px solid #dc3545;
}

.tab-button:hover {
  color: #dc3545;
}

.placeholder-content {
  text-align: center;
  padding: 30px;
}

.net-sheet-result {
  font-size: 40px;
  font-family: "DM Sans", DM Sans !important;
}

.net-sheet-edit-btn {
  cursor: pointer;
  margin-bottom: 0;
}

.net-sheet-edit-input {
  width: fit-content;
  border: none;
  background: transparent;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #212529;
  outline: none;
}

.seller-net-sheet-description {
  margin-bottom: 0;
}

.refresh-icon {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.show-hide-button {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.show-hide-button:hover {
  background-color: #0056b3;
}

.detailed-closing-costs {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
}

.closing-cost-section {
  margin-top: 10px;
}

.cost-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.cost-item span {
  /* font-weight: bold; */
  text-align: left;
}

.tax-toggle-container {
  display: flex;
  gap: 10px;
}

.toggle-option {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tax-toggle-container input[type="radio"] {
  border: none;
  outline: none;
}

.save-estimate-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  color: #d94b57;
  border: 1px solid #d94b57 !important;
  outline: none;
  background-color: transparent !important;
  margin-top: 16px;
  height: 34px;
}

.estimate-item {
  background: #f6f7f9;
  border-radius: 8px;
  padding: 12px;
  margin: 6px 0;
  position: relative;
  cursor: pointer;
}

.estimate-item:hover {
  background-color: #f5f5f5;
}

.estimate-item.selected-estimate {
  border: 2px solid #dc3545;
}

.edit-pencil {
  top: 10px;
  width: 16px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

/* Modal Section Titles */
.modal-section-title {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
}

/* Title Fees & Other Fees */
.fee-group {
  margin-bottom: 15px;
}

.fee-label {
  font-weight: 500;
  color: #444;
}

.fee-input {
  width: 100%;
  padding: 8px;
}

/* Other Fees Layout */
.other-fee-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Fee Inputs */
.fee-name-input, .fee-amount-input {
  flex: 1;
  padding: 8px;
}

/* Delete Button */
.delete-btn {
  padding: 5px 10px;
}

/* Add Fee Button */
.add-fee-btn {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-weight: 600;
}

/* Modal Footer Buttons */
.modal-cancel-btn, .modal-save-btn {
  padding: 10px 20px;
  font-weight: 600;
}

.editable-fee-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  justify-content: space-between;
}

.editable-fee-row > .label-div {
  width: 50%;
}

.editable-input-value-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.editable-input-value-container img {
  width: 12px;
  cursor: pointer;
  margin-left: 10px;
}

.fee-add-icon {
  width: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.edit-fee-modal-footer {
  border-top: 0;
}

.edit-fee-modal-footer button {
  margin-bottom: 0;
  height: 35px;
  padding: 0;
  width: 100px;
}

.delete-estimate-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 14px;
  cursor: pointer;
}

.estimate-title {
  text-transform: capitalize;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
}

.estimate-item-line {
  margin-bottom: 0;
  font-size: 14px;
  color: black;
}

.estimate-date-item {
  font-size: 12px;
  color: #999999;
  text-align: right;
  margin-bottom: 0;
}

.tax-type-radio input[type="radio"]:checked:after {
  border: 2px solid transparent !important; 
}

.ios-radio-label {
  margin-left: 8px;
}

.edit-closing-costs-confirmation-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.edit-closing-costs-confirmation-btns button {
  margin-bottom: 0;
}

.multiple-offer-row-space-btwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.multiple-offer-row-space-btwn p {
  margin-bottom: 0;
  font-size: 14px;
  color: black;
}

.estimate-item .go-to-offer {
  color: #28a745;
  font-weight: 600;
  font-size: 14px;
  border: 0;
  background: transparent;
  margin-bottom: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}

.share-pdf-btn {
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #dc3545;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #dc3545;
  margin-bottom: 8px;
}

.share-pdf-btn:hover {
  background-color: transparent;
  border: 1px solid #dc3545;
  color: #dc3545;
}

.full-report-button-container {
  margin: 20px 0 30px;
}

.full-report-button {
  background: white;
  color: #4b4b4b;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 8px;
}

.full-report-button img {
  margin-right: 6px;
}

.snapshot-container {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: monospace;
  max-height: 500px;
  margin-top: 16px;
  overflow-y: auto;
}

.snapshot-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.snapshot-footer button {
  margin-bottom: 0;
}

.settle-offer-icon {
  width: 20px;
}

.settle-offer-table td {
  padding: 12px 15px;
  height: auto;
  text-align: left;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settle-offer-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.settle-offer-table tr:hover {
  background-color: #f1f1f1;
}

.settle-offer-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.settle-btn {
  background-color: #ff214f !important;
  border: 1px solid #ff214f;
}

.download-doc {
  width: 20px;
  margin-left: 20px;
}

.offer-status-container button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: auto;
  margin-left: 0 !important;
  margin-right: 6px;
}

.req-change-container {
  margin: 20px 0;
  background: #f6f7fa;
  padding: 20px;
  border-radius: 4px;
}

.req-change-container > div {
  border-bottom: 2px solid #fff;
  margin: 2px 0;
  padding: 5px;
}

.req-change-container > div:not(:last-child) {
  border-bottom: 2px solid #fff;
}

.question-icon {
  width: 20px;
  height: 20px;
  cursor: pointer
}

.chatbox-close-btn {
  position: absolute;
  width: 18px;
  right: 8px;
  border: 1px solid red;
  padding: 3px;
  border-radius: 9px;
  cursor: pointer;
}

.relative {
  position: relative;
}

.chatbox-container {
  position: absolute;
  width: 300px;
  height: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  right: 34px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: -135px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-message {
  padding: 8px;
  border-radius: 10px;
  font-size: 15px;
  margin-bottom: 5px;
  max-width: 90%;
  word-wrap: break-word;
}

.chat-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-input input {
  font-size: 14px;
}

.chat-send-button {
  background-color: transparent;
  border: 0;
  margin: 0;
}

.chat-send-button:hover {
  background-color: transparent;
}

.chat-send-button img {
  width: 20px;
} 

.chat-title {
  color: #000 !important;
  font-weight: bold;
  margin-bottom: 0;
  max-width: 95%; 
}

.chatbox-header {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 4px;
}

.sent {
  background-color: #E1F5FE;
  align-self: flex-end;
}

.received {
  background-color: #F0F0F0;
  align-self: flex-start;
}

.message-time {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  text-align: right;
}


@media (max-width: 1210px) {
  /* .btn-review {
    margin-right: 10px;
  } */
  button {
    margin-bottom: 20px;
  }
  .homeoffer-details button {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1209px) {
  .settle-offer-action-buttons {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .homeoffer-details {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .offer-comments-filter-container {
    flex-direction: column;
  }
  .mobile-responsive-alert {
    font-size: 14px;
    padding: 10px;
    flex-wrap: wrap !important;
  }

  .bold-td {
    width: 50% !important;
  }

  .house img {
    width: 100%;
    object-fit: contain;
  }
  .homeoffer-details ul li {
    margin-left: 15px;
  }
  .btns-mobile-center {
    justify-content: center !important;
    text-align: center !important;
  }
  .flexEnd {
    align-items: center;
  }
  .mt-95 {
    margin-top: 55px;
  }
  .mb-457 {
    margin-bottom: 200px;
  }
  .overflow {
    overflow: hidden;
    padding: 0;
  }
  .view-counter-offer {
    min-height: 44px;
    height: auto;
  }
  .otheragent-header-btns {
    flex-direction: column;
    align-items: center;
  }
  .otheragent-header-btns button {
    margin-bottom: 10px !important;
  }
  .mob-only-show-offer-trends-btn {
    display: block;
    font-family: "Raleway", DM Sans;
    min-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    color: #28a745;
    font-size: 12px;
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 26px;
  }
  .mob-only-show-offer-trends-btn img {
    width: 14px;
    margin-right: 6px;
  }
  .seller-net-doughnut-container {
    width: 250px;
    height: 250px
  }
  .seller-net-doughnut-container canvas {
    width: 250px !important;
    height: 250px !important;
  }
  .net-sheet-result {
    font-size: 35px;
  }
  .refresh-icon {
    top: 14px;
    right: 14px;
  }
  .tab-button {
    padding: 12px 6px;
  }
  .seller-net-mob-form-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .seller-net-sheet-description {
    margin: 20px 0;
  }
  .seller-net-sheet-form-container {
    margin-top: 20px;
  }
  .mobile-offer-term-buttons {
    flex-direction: column;
  }
  .mobile-offer-term-buttons button {
    margin-right: 0 !important;
  }
  .mobile-offer-term-buttons span button {
    width: 100%;
    margin-bottom: 10px;
  }
  .mob-only-show-offer-trends-btn {
    width: 100%;
  }
  .editable-fee-row {
    gap: 10px;
  }
  .editable-fee-row > .label-div {
    width: auto;
  }
  .edit-closing-costs-confirmation-btns {
    flex-direction: column;
    align-items: center;
  }
  .edit-closing-costs-confirmation-btns button {
    margin-bottom: 0;
  }
  .net-sheet-result-container {
    padding: 12px;
  }
  .dashboard-content .container, .mob-padd-0 {
    padding: 0;
  }
}